import React from "react";



const FaqHero = () => (
  <div className="d-flex flex-column h-100">
  <div className='container rounded'>
  </div>
  <div className="rounded bg-white mx-4 mt-4">
    <h3 className="text text-left mt-2 mb-2">Frequently Asked Questions</h3>
    <h5 className="text text-left mt-2 mb-2">How does this service work?</h5>
    <p className="body mx-3">
      As a signing agent or field inspector, you can register using the <a href="/sign-up">Sign Up</a> link.  Once you register, our team will have a chance to review your information and approve your 
      registration.  As soon as you are approved, you'll be eligible to start receiving notifications of assignments in your area and you'll have an opportunity to bid on the assignments.  These notifications
      are sent via SMS, so please be sure to Opt In to receive SMS messages.</p>
      <p className="body mx-3">
      You will receive a follow up message for all assignments in which you submit an offer, confirming whether your offer is accepted or declined.
    </p>
    <h5 className="text text-left mt-2 mb-2">If I am assigned for a closing, how will I be paid?</h5>
    <p className="body mx-3">
      We've spent over twenty years in the signing agent business and are very familiar with the hassles of receiving payment for our work.  Our top priority at Pinpoint Field Services
      is to ensure we are a trusted partner, and prompt payment for services delivered is an important part of being a trusted partner.  As soon as you receive an assignment,
      our team will contact you and work through any additional details, including the payment process.
    </p>
    <h5 className="text text-left mt-2 mb-2">How do I login?</h5>
    <p className="body mx-3">
      Your account is tied to your email address.  If you are unsure of your password or forgot your password, please select the login link and utilize the Forgot Password link.
    </p>
    <h5 className="text text-left mt-2 mb-2">I've recently become certified in a new state, how do I receive assignments for this new area?</h5>
    <p className="body mx-3">
      Once you are logged in, you can access your profile by selecting the link with your name in the upper right corner.  Here you can update your profile,
      including the ability to select up to five states in which you are certified.
    </p>
    <h5 className="text text-left mt-2 mb-2">What if I have a question that isn't answered here?</h5>
    <p className="body mx-3">
      Please use the below form to submit any question you may have.  Additionally, if you've encountered an issue with our service or have suggestions for improvements,
      please let us know as well.  We really do appreciate your feedback!
    </p>
  </div>
</div>
);

export default FaqHero;