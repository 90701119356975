// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import mixpanel from 'mixpanel-browser';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.bundle.min';





import './index.css';

mixpanel.init('ffd945ed5c34c1644eab0f4a66cc8b1d', {track_pageview: "url-with-path", record_sessions_percent: 15}
);

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root'),
);

