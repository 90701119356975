// src/components/notary-pending.js

import React, { useState, Fragment, useEffect } from 'react';
import { RestHelper } from '../helper/restHelper'
import { NotaryViewRequestDetails, PopupOverlay, PopupOverlayResponseMessage, PopupOverlayMultipleMessage } from '../components'



const NotaryScheduled = (profileEmail) => {
  const [message, setApiRes] = useState([])
  const [viewState, setViewState] = useState(false)
  const [viewRequest, setViewRequest] = useState(false)
  const [requestId, setRequestId] = useState('')
  const [dataFetched, setDataFetched] = useState(true)
  const [showPopup, setShowPopup] = useState(false)
  const [showPopupResponse, setShowPopupResponse] = useState(false)
  const [passedMessage, setPassedMessage] = useState('')
  const [passedMessage1, setPassedMessage1] = useState('')
  const [passedMessage2, setPassedMessage2] = useState('')
  const [passedObject, setPassedObject] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [passedData, setPassedData] = useState({
    requestId: '',
    profileEmail: ''
  })


  const activeUser = { 'activeUser': profileEmail.profileEmail }
  const action = { 'action': 'Scheduled' }

  const api = RestHelper()

  useEffect(() => {
    changeView();
  }, []);

  // if Check Pending Requests is selected, we'll query for pending requests

  const dateFormat = (inputDate) => {
    const dateArray = inputDate.split("-")
    const date = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0]
    return date
  }

  const scanBackCheck = (checkFlag) => {
    var scanBackValue = ''
    if (checkFlag === 'true') {
      scanBackValue = 'SCAN BACKS ARE REQUIRED'
    }
    else {
      scanBackValue = 'NONE'
    }
    return scanBackValue

  }

  const changeView = () => {
    if (viewState) {
      setViewState(false);
      setViewRequest(false)
      setDataFetched(false)
      setApiRes([])
    }
    else {
      setViewState(true);
      setViewRequest(false)
      const options = {}
      if (dataFetched) {
        api
          .postCreate('/get-notary-open-requests', options, action)
          .then(res => updateResponse(res))
          .catch(err => console.log(err))
      }
      setDataFetched(true)
    }
  }

  function updateResponse(res) {
    setApiRes(res)
  }

  const getRequest = (data) => {
    setRequestId(data.RequestId)
    setPassedData({ ...passedData, requestId: requestId, profileEmail: profileEmail.profileEmail })
    setViewRequest(true);
    setViewState(false)
  }

  function confirmComplete(e) {
    if (e.scanBack == 'true') {
      var messageToSend1 = 'Please select Confirm to acknowledge that the appointment ' + e.Appt_Name + ' is complete and generate a notification to the Escrow Office and Pinpoint Field Services Admin.'
      var messageToSend2 = 'Please remember to upload all required scans to the portal within the next two hours or communicate with the Escrow Officer if scanbacks will be delayed.  Thank you!'
    }
    else {
      var messageToSend1 = 'Please select Confirm to acknowledge that the appointment ' + e.Appt_Name + ' is complete. This will generate a completion notice to the Escrow Officer and Pinpoint Field Services Admin.  Thank you!'
      var messageToSend2 = ''
    }
    setPassedMessage1(messageToSend1)
    setPassedMessage2(messageToSend2)
    setPassedObject(e)
    setViewState(false)
    setShowPopup(true)
  }

  function showCompleteMessage(e) {
    var messageToSend = e.responseMessage
    setPassedMessage(messageToSend)
    setPassedObject(false)
    setIsLoading(false)
    setViewState(false)
    setShowPopupResponse(true)
  }

  function clearModal() {
    setShowPopup(false)
    setShowPopupResponse(false)
    setViewState(true)
  }

  function confirmAction(e) {
    setShowPopup(false)
    setIsLoading(true)
    var reqData = { 'data': { 'requestId': e.RequestId } }
    var action = { 'Action': 'markComplete' }
    const options = {}
    const updateDetails = { reqData, action }
    api
      .postCreate('/update-notary-request-details', options, updateDetails)
      .then(res => showCompleteMessage(res))
      .catch(err => console.log(err))
    setViewState(false)
  }



  return (
    <div>
      {viewRequest
        ? (<div className='container mb-3 border rounded bg-light'>
          <div className='row rounded bg-white'>
            <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() => changeView()}>
              <span aria-hidden='false'>&times;</span>
            </button>
          </div>
          <Fragment>
            <NotaryViewRequestDetails requestId={requestId} profile={activeUser} status='scheduled' role='notary'/>
          </Fragment>
        </div>)
        : <div></div>
      }
      {viewState && (
        <div className="mt-5">
          <h6 className="muted">Here are your current assigned requests:</h6>
          <div className="container-fluid rounded mb-2">
            <div className="card mx-2">
              <div className="card-body mb-2">
                {message.map((items =>
                  <div className='container bg-light rounded border mb-2'>
                    <button className='btn btn-dark mt-3 mb-2 rounded' type='button' onClick={() => getRequest(items)}>Click Here to Access Assignment </button>
                    <div className="row">
                      <div className="col">
                        <h6 className="card-text">Assignment Name:</h6>
                      </div>
                      <div className="col">
                        <p className="card-text">{items.Appt_Name}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="card-text">Status:</h6>
                      </div>
                      <div className="col">
                        <p className="card-text">{items.Status}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="card-text">File Number:</h6>
                      </div>
                      <div className="col">
                        <p className="card-text">{items.fileNumber}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="card-text">Location:</h6>
                      </div>
                      <div className="col">
                        <p className="card-text">{items.Appt_City}, {items.Appt_State}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="card-text">Date:</h6>
                      </div>
                      <div className="col">
                        <p className="card-text">{dateFormat(items.Appt_Date)}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 classname="card-text">Time:</h6>
                      </div>
                      <div className="col">
                        <p className="card-text">{items.Appt_Time} {items.Appt_TimeZone}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="card-text">Signing Agent Fee:</h6>
                      </div>
                      <div className="col">
                        <p className="card-text">${items.Appt_Bid}</p>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col">
                        <h6 classname="card-text">Scan Backs:</h6>
                      </div>
                      <div className="col">
                        <p className="card-text">{scanBackCheck(items.scanBack)}</p>
                      </div>
                    </div>
                    {items.showCompleteButton &&
                      <div>
                        {items.markedCompleted 
                          ? (<button className='btn btn-light border border-secondary mt-3 mb-2 rounded' disabled type='button' onClick={() => confirmComplete(items)}>Assignment Marked Complete</button>)
                          : (<button className='btn btn-success border border-secondary mt-3 mb-2 rounded' type='button' onClick={() => confirmComplete(items)}>Mark Assignment Complete</button>)
                        }
                      </div>
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading &&
        (<div class="d-flex justify-content-center mb-4 mt-4">
          <div className="spinner-border" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>)}
      <Fragment>
        {showPopup && <PopupOverlayMultipleMessage clearModal={clearModal} confirmAction={confirmAction} passedMessage1={passedMessage1} passedMessage2={passedMessage2} passedObject={passedObject} />}
        {showPopupResponse && <PopupOverlayResponseMessage clearModal={clearModal} confirmAction={confirmAction} passedMessage={passedMessage} passedObject={passedObject} />}
      </Fragment>
    </div>
  )
};

export default NotaryScheduled;